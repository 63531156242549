<div class="full-page bg-primary w-100">
  <div class="overflow-hidden p-0">
    <div class="row">
      <div class="d-flex d-lg-none register-logo-bg align-items-center justify-content-center">
        <img class="register-logo" src="../../../../../assets/images/login-registration/club-name.png" />
      </div>
      <div class="col-lg-6 d-none d-lg-block login-banner"></div>
      <div class="user-login-panel col-lg-6 col-12 text-left bg-white">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col-12">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
