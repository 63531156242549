<!--Banner-->
<div class="d-none d-lg-block">
  <div class="banner" role="banner">
    <h1>{{ 'lotto.page.title' | translate }}</h1>
  </div>
</div>
<!--Poimt Summary Bar-->
<div class="container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards/raffles'" [backButtonTitle]="'Prize Draws'"></fgb-points-summary-bar>
</div>
<!--Lotto Details-->
<fgbcl-lotto-details [lottoId]="id"></fgbcl-lotto-details>
<!--Back Button-->
<div class="container">
  <div class="back-btn pt-3 cursor-pointer d-none d-lg-flex align-items-center" [routerLink]="'/rewards/raffles'">
    <span class="material-icons"> chevron_left </span>
    <span class="font-size-14 font-weight-bold">Back to Prize Draws</span>
  </div>
</div>
