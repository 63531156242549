<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex justify-content-between">
    <a class="nav-item font-weight-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">{{ 'nav.go.to.home.page' | translate }}</label>
      <img alt="" class="home-logo" src="assets/images/MainLogo.svg" draggable="false" />
    </a>

    <div class="d-flex">
      <!-- Notification Button -->
      <fgb-notification-button></fgb-notification-button>

      <button class="nav-item font-weight-bold navbar-toggler m-0 px-2" (click)="isCollapsed = !isCollapsed">
        <span class="icon material-icons material-mobile-nav">menu</span>
      </button>
    </div>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown bg-primary">
    <div class="d-flex flex-column mobile-nav-menu-content">
      <!-- Close Button -->
      <button
        class="close-button position-absolute border-0"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="icon font-weight-bold icon-cancel-circle"></span>
      </button>

      <div class="card-member mx-4 mt-3">
        <ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
          <div class="text-white">
            <div class="d-flex flex-column justify-content-between h-100">
              <div class="d-block">
                <!--Logo-->
                <img alt="" class="card-logo mb-3" src="assets/images/MainLogo.svg" draggable="false" />
                <!--Name and ID-->
                <div class="card-id text-left">
                  <p class="mb-0 card-name" *ngIf="memberDetails$ | async as memberDetails">
                    {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
                  </p>
                  <div class="d-flex mt-2 pb-3 border-bottom"></div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #loadingRef>
          <fgbcl-loading></fgbcl-loading>
        </ng-template>
      </div>

      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container">
        <a
          class="nav-item large-item"
          [routerLink]="['/account']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">person</span>
            <div class="nav-text">{{ 'nav.account' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>
        <a class="nav-item" [routerLink]="['/']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">flag</span>
            <div class="nav-text">{{ 'nav.achievements' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>
        <a
          class="nav-item"
          [routerLink]="['/rewards/raffles']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">confirmation_number</span>
            <div class="nav-text">{{ 'nav.prize.draws' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>
        <a class="nav-item" [routerLink]="['/badges']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">local_police</span>
            <div class="nav-text">{{ 'nav.badges' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/rewards/wallet']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text">{{ 'nav.wallet' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>

        <a class="nav-item" [routerLink]="['/contact']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">email</span>
            <div class="nav-text">{{ 'nav.contact.us' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>

        <a class="nav-item" [routerLink]="['/terms']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">article</span>
            <div class="nav-text">{{ 'nav.terms' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>

        <a class="nav-item" [routerLink]="['/faq']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">help</span>
            <div class="nav-text">{{ 'nav.faq' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>

        <a class="nav-item text-danger" (click)="logout()">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">power_settings_new</span>
            <div class="nav-text">{{ 'nav.log.out' | translate }}</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
