import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { RegistrationStepService, RegistrationStepQuery, LoggingService, WizardType, StepType } from '@fgb/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fgb-registration-navigation',
  templateUrl: './registration-navigation.component.html',
  styleUrls: ['./registration-navigation.component.scss'],
})
export class RegistrationNavigationComponent implements OnInit, OnDestroy {
  @Input() stepType: StepType;
  @Input() nextButtonDisabled: boolean = false;
  @Input() backButttonNavigate: boolean = false;
  @Output() nextButtonClicked = new EventEmitter();
  @Output() backButtonClicked = new EventEmitter();
  hasPreviousStep: boolean = true;
  isFirstTimeRegistration: boolean = false;
  private routerSubscription: Subscription;
  @Input() backLabel: string;
  @Input() nextLabel: string;
  @Input() hideNextBtn: boolean = false;

  constructor(
    private registrationStepQuery: RegistrationStepQuery,
    public registrationStepService: RegistrationStepService,
    private router: Router,
    private logService: LoggingService
  ) {}

  ngOnInit() {
    this._updateStatus();

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Reupdate the status variables each time we change step.
        this._updateStatus();
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  /** Navigate to the previous step. */
  goToPreviousStep(): void {
    if (this.backButttonNavigate) {
      this.backButtonClicked.emit();
    } else {
      this.logService.debug('Previous registration step button clicked.');
      this.registrationStepService.navigateToPreviousStep(this.stepType);
    }
  }

  /** Recalculate the status variables. */
  private _updateStatus(): void {
    this.hasPreviousStep = this.registrationStepQuery.hasPreviousStep(this.stepType);
    this.isFirstTimeRegistration = this.registrationStepQuery.getWizardType() === WizardType.FirstTimeRegistration;
  }
}
