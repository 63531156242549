<nav class="nav nav-secondary mixed-loyalty-nav my-0">
  <a class="nav-item" [routerLink]="['/']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <div class="material-icons icon">flag</div>
    <label>{{ 'mixed.nav.achievement' | translate }}</label>
  </a>
  <a class="nav-item" [routerLink]="['/badges']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <div class="icon material-icons" aria-hidden="true">local_police</div>
    <label>{{ 'account.nav.badge' | translate }}</label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/rewards/raffles']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <fgbcl-loyalty-icon [loyaltyType]="'lotto'"></fgbcl-loyalty-icon>
    <label>{{ 'mixed.nav.raffles' | translate }}</label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/rewards/wallet']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <fgbcl-loyalty-icon [loyaltyType]="'wallet'"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.wallet' | translate }}</label>
  </a>
</nav>
