<div class="container">
  <ng-container *ngIf="campaignOffers$ | async as featuredCampaigns">
    <ng-container *ngIf="featuredCampaigns.CampaignsAndEntitlements?.length">
      <div class="offer-items">
        <div class="d-flex flex-row justify-content-between align-items-center mt-4 mb-2">
          <h5 class="mb-0 text-capitalize">{{ 'campaign.offers.subheading' | translate }}</h5>
        </div>
        <div class="d-none d-lg-block">
          <owl-carousel-o [options]="customOptions" (dragging)="isDragging = $event.dragging">
            <ng-container *ngFor="let offer of featuredCampaigns.CampaignsAndEntitlements">
              <span>{{ offer }}</span>
              <ng-template [width]="224" carouselSlide>
                <fgb-campaigns-list-item
                  [campaignEntitlement]="offer"
                  routerLink="{{ navigateToCampaignOffer(offer) }}"
                ></fgb-campaigns-list-item>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
        <div class="d-block d-lg-none">
          <owl-carousel-o [options]="customOptions" (dragging)="isDragging = $event.dragging">
            <ng-container *ngFor="let offer of featuredCampaigns.CampaignsAndEntitlements">
              <ng-template [width]="165" carouselSlide>
                <fgb-campaigns-list-item
                  [campaignEntitlement]="offer"
                  routerLink="{{ navigateToCampaignOffer(offer) }}"
                ></fgb-campaigns-list-item>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
