<div class="full-page w-100 d-md-flex d-block px-0 h-100">
  <!--Left Side-->
  <div class="left-side-login">
    <img
      class="shared-login-banner d-md-block d-none h-100"
      draggable="false"
      src="assets/images/login-registration/login-banner.jpg"
    />
  </div>
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="p-4 right-side-login">
    <div class="login-container">
      <img class="logo" src="assets/images/login-registration/club-name.png" />
      <div
        class="panel-text text-center mlb-text font-size-14"
        [innerHTML]="'login.page.description' | translate | markdownTranslate"
      ></div>
      <fgb-seatgeek-user-login></fgb-seatgeek-user-login>

      <ng-container *ngIf="errorCode$ | async as errorCode">
        <div class="text-danger text-center mt-5 font-weight-bold">
          {{ errorCode | translate }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
