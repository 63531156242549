import { Component, OnInit } from '@angular/core';
import { SeatGeekAuthService } from '@fgb/core';


@Component({
  selector: 'fgb-seatgeek-user-login',
  templateUrl: './seatgeek-user-login.component.html',
  styleUrls: ['./seatgeek-user-login.component.scss'],
})
export class SeatGeekUserLoginComponent implements OnInit {
  constructor(private seatGeekAuthService: SeatGeekAuthService) {}

  ngOnInit() {}

  loginSeatGeek() {
    this.seatGeekAuthService.login().toPromise();
  }
}
