<nav class="navbar bg-primary navbar-dark navbar-expand-lg d-none d-lg-flex">
  <a class="navbar-brand py-0" [routerLink]="['/']" routerLinkActive="router-link-active">
    <label id="homeLink" class="accessibility-only">{{ 'nav.go.to.home.page' | translate }}</label>
    <img alt="" class="ml-1 h-100" aria-labelledby="homeLink" src="assets/images/MainLogo.svg" draggable="false" />
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Left Side Content -->
  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container">
      <ul class="navbar-nav mr-auto" #greedyNav>
        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/']" routerLinkActive="router-link-active">{{
            'nav.archievements' | translate
          }}</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/rewards/raffles']" routerLinkActive="router-link-active">{{
            'nav.prize.draws' | translate
          }}</a>
        </li>

        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/rewards/wallet']" routerLinkActive="router-link-active">{{
            'nav.wallet' | translate
          }}</a>
        </li>

        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/badges']" routerLinkActive="router-link-active">{{
            'nav.badges' | translate
          }}</a>
        </li>

        <li class="nav-item active more-dropdown">
          <div ngbDropdown #ddMore="ngbDropdown" [autoClose]="'outside'" role="group">
            <a class="nav-link mx-1" ngbDropdownToggle focusTabBtn>
              <span>{{ 'nav.help' | translate }}</span>
            </a>
            <div ngbDropdownMenu class="shadow" (click)="closeDropDown(ddMore)">
              <div>
                <a [routerLink]="['/faq']" routerLinkActive="router-link-active" class="pl-3">
                  {{ 'nav.faq' | translate }}
                </a>
              </div>
              <div class="py-1">
                <a [routerLink]="['/contact']" routerLinkActive="router-link-active" class="pl-3">
                  {{ 'nav.contact.us' | translate }}
                </a>
              </div>
              <div>
                <a [routerLink]="['/terms']" routerLinkActive="router-link-active" class="pl-3">
                  {{ 'nav.terms' | translate }}
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- Right Side Content -->
    <div class="form-inline my-2 my-lg-0">
      <div class="member" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'" focusTab>
        <button
          class="nav-link btn text-white d-flex align-items-center mx-2 px-0 justify-content-end"
          id="namedropdown"
          ngbDropdownToggle
          attr.aria-label="{{ 'nav.account.dropdown.screenreader' | translate }}"
          tabindex="-1"
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-inline-block">
            <div class="font-size-14 text-left text-capitalize">{{ memberDetails!.FirstName }}</div>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          class="shadow text-center dropdown-menu p-0 account-dropdown mt-2"
        >
          <div class="text-info">
            <a
              [routerLink]="['/account']"
              routerLinkActive="router-link-active"
              class="d-flex align-items-center account-btn text-black bg-white py-0 px-2 nav-item-account-height rounded-top"
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon pl-1 mt-1 mr-2 mb-3" aria-hidden="true">person</span>
              <div class="dropdown-link text-left">
                <div class="my-2 font-size-14 font-weight-bold">{{ 'nav.account' | translate }}</div>
                <div class="font-size-12" *ngIf="card$ | async as card">{{ card.ProductCode | productCode | translate }}</div>
              </div>
            </a>
          </div>
          <hr class="text-muted w-75 my-0" />
          <div>
            <button
              class="logout-btn d-flex align-items-center bg-white px-2 nav-item-fixed-height rounded-bottom"
              (click)="logout()"
              ngbDropdownItem
              attr.aria-label="{{ 'nav.log.out.screenreader' | translate }}"
            >
              <span class="icon material-icons material-dropdown-icon pl-1 mr-2 text-danger" aria-hidden="true"
                >power_settings_new</span
              >
              <div class="dropdown-link text-left">
                <div class="text-danger py-1 font-size-14 font-weight-bold" ngbDropdownItem (click)="logout()">
                  {{ 'nav.log.out' | translate }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <fgbcl-loading [size]="'small'"></fgbcl-loading>
  </div>
</ng-template>
