<div class="home-page d-flex flex-column">
  <div class="banner d-none d-lg-flex" role="banner">
    <img class="home-banner" src="assets/images/HomeDBanner.png" draggable="false" />
  </div>
  <div class="container mixed-nav card-member d-block d-lg-none order-1 bg-white mb-3">
    <fgb-member-card showSeating="true"></fgb-member-card>
  </div>
  <div class="mixed-nav mb-4 order-2 order-lg-1">
    <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
  </div>
  <div class="container voucher pb-4 order-4 order-lg-4">
    <fgb-vouchers></fgb-vouchers>
  </div>

  <div class="scorecard-list-view order-4 order-lg-4">
    <!--Scorecard carousel-->
    <fgbcl-scorecard-list-view [icon]="'chevron_right'" [version2Design]="true" [phaseThree]="true"></fgbcl-scorecard-list-view>
  </div>
  <!-- Announcements -->
  <div class="container order-4 order-lg-4">
    <fgbcl-announcements></fgbcl-announcements>
  </div>
</div>
