<div class="d-none d-lg-block">
  <div class="banner" role="banner">
    <h1>{{ 'wallet.page.title' | translate }}</h1>
  </div>
</div>

<div class="container mixed-nav card-member d-block d-lg-none order-1 bg-white mb-3">
  <fgb-member-card showSeating="true"></fgb-member-card>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<!-- V2 Wallet -->
<ng-container
  *ngIf="
    {
      walletItemsUnclaimed: walletItemsUnclaimed$ | async
    } as walletItems;
    else noWalletItems
  "
>
  <div class="wallet-redeem-carousel pt-4">
    <div class="container">
      <ng-container *ngIf="walletItems.walletItemsUnclaimed && walletItems.walletItemsUnclaimed.length; else noWalletItems">
        <fgbcl-rewards-wallet-redeem-list [unRedeemedItems]="walletItems.walletItemsUnclaimed"></fgbcl-rewards-wallet-redeem-list>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #noWalletItems>
  <h5 class="no-wallet-items mb-3 font-weight-bold text-capitalize">{{ 'rewards.wallet.no.items' | translate }}</h5>
  <div class="border">
    <div class="no-wallet-items-description font-weight-bold font-size-12 text-center text-grey py-3">
      {{ 'rewards.wallet.no.items.description' | translate }}
    </div>
  </div>
</ng-template>

<div class="container mt-3 text-center">
  <a class="font-size-14 text-info claimed-text font-weight-bold" [routerLink]="['/rewards/claimed']">
    {{ 'rewards.wallet.view.claimed' | translate }}
  </a>
</div>
