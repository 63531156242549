<nav class="nav nav-secondary account-details-nav">
  <a class="nav-item" [routerLink]="['/account']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <div class="icon material-icons" aria-hidden="true">person</div>
    <label>{{ 'account.nav.profile' | translate }}</label>
  </a>
  <a class="nav-item" [routerLink]="['/badges']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <div class="icon material-icons" aria-hidden="true">local_police</div>
    <label>{{ 'account.nav.badge' | translate }}</label>
  </a>
</nav>
