import { Component, OnInit } from '@angular/core';
import { ProviderName, TransactionType } from '@fgb/core';
import { Regex } from 'src/app/shared/utilities/regex';
@Component({
  selector: 'fgb-ecash-page',
  templateUrl: './ecash-page.component.html',
  styleUrls: ['./ecash-page.component.scss'],
})
export class EcashPageComponent implements OnInit {
  transactionType = TransactionType;
  regex = Regex;
  provider = ProviderName.FreedomPay;

  constructor() {}

  ngOnInit() {}
}
