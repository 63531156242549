// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from 'src/app/shared/shared.module';
import { EcashModule } from 'src/app/modules/ecash/ecash.module';
// Pages
import { RegistrationEntryPageComponent } from './pages/registration-entry-page/registration-entry-page.component';
import { RegistrationSuccessPageComponent } from './pages/registration-success-page/registration-success-page.component';
import { RegistrationPaymentCallbackPageComponent } from './pages/registration-payment-callback-page/registration-payment-callback-page.component';
// Shared
import { RegistrationNavigationComponent } from './components/registration-navigation/registration-navigation.component';
// Steps
import { RegistrationPersonalDetailsComponent } from './pages/steps/registration-personal-details/registration-personal-details.component';
import { RegistrationTermsAndConditionsComponent } from './pages/steps/registration-terms-and-conditions/registration-terms-and-conditions.component';
import { RegistrationProviderAuthenticationComponent } from './pages/steps/registration-provider-authentication/registration-provider-authentication.component';
import { RegistrationProviderTranslationComponent } from './pages/steps/registration-provider-translation/registration-provider-translation.component';
import { RegistrationShippingAddressComponent } from './pages/steps/registration-shipping-address/registration-shipping-address.component';
import { RegistrationAddChildrenComponent } from './pages/steps/registration-add-children/registration-add-children.component';
import { RegistrationMembershipOptionsComponent } from './pages/steps/registration-membership-options/registration-membership-options.component';
import { RegistrationPaymentComponent } from './pages/steps/registration-payment/registration-payment.component';
import { RegistrationOperatorNameComponent } from './pages/steps/registration-operator-name/registration-operator-name.component';
import { RegistrationChildQuestionsComponent } from './pages/steps/registration-child-questions/registration-child-questions.component';
import { RegistrationQuestionsComponent } from './pages/steps/registration-questions/registration-questions.component';
import { RegistrationFailurePageComponent } from './pages/registration-failure-page/registration-failure-page.component';
import { RegistrationChooseMembershipOptionTypeComponent } from './pages/steps/registration-choose-membership-option-type/registration-choose-membership-option-type';
import { RegistrationOptOutAlertsComponent } from './pages/steps/registration-opt-out-alerts/registration-opt-out-alerts.component';
import { RegistrationOptOutEmailsComponent } from './pages/steps/registration-opt-out-emails/registration-opt-out-emails.component';
import { RegistrationPartnerInviteComponent } from './pages/steps/registration-partner-invite/registration-partner-invite.component';
import { RegistrationProviderOauthComponent } from './pages/steps/registration-provider-oauth/registration-provider-oauth.component';
import { RegistrationGetProviderDetailsComponent } from './pages/steps/registration-get-provider-details/registration-get-provider-details.component';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { TermsAndConditionsModule } from '@fgb/portal-component-library/src/lib/club/terms-and-conditions';

@NgModule({
  declarations: [
    RegistrationEntryPageComponent,
    RegistrationSuccessPageComponent,
    RegistrationNavigationComponent,
    RegistrationPaymentCallbackPageComponent,
    // Steps
    RegistrationPersonalDetailsComponent,
    RegistrationTermsAndConditionsComponent,
    RegistrationProviderAuthenticationComponent,
    RegistrationProviderTranslationComponent,
    RegistrationShippingAddressComponent,
    RegistrationAddChildrenComponent,
    RegistrationMembershipOptionsComponent,
    RegistrationPaymentComponent,
    RegistrationOperatorNameComponent,
    RegistrationChildQuestionsComponent,
    RegistrationQuestionsComponent,
    RegistrationOptOutAlertsComponent,
    RegistrationFailurePageComponent,
    RegistrationChooseMembershipOptionTypeComponent,
    RegistrationPartnerInviteComponent,
    RegistrationOptOutEmailsComponent,
    RegistrationProviderOauthComponent,
    RegistrationGetProviderDetailsComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
    SharedModule,
    EcashModule,
    TermsAndConditionsModule,
  ],
  exports: [RegistrationEntryPageComponent, RegistrationSuccessPageComponent],
})
export class RegistrationModule {}
