<div class="d-none d-lg-block">
  <div class="banner" role="banner">
    <h1>{{ name$ | async }}</h1>
  </div>
</div>

<div class="container mixed-nav card-member d-block d-lg-none order-1 bg-white mt-4">
  <fgb-member-card showSeating="true"></fgb-member-card>
</div>

<div class="container mt-lg-4 mt-2">
  <div class="row">
    <div class="col-12 col-lg-6 order-lg-2 order-1">
      <h5 class="text-left my-3 mt-lg-0">{{ 'account.details.title' | translate }}</h5>
      <fgb-account-details></fgb-account-details>
    </div>
    <div class="col-12 col-lg-6 order-lg-1 order-2">
      <div class="d-none d-lg-block">
        <h5 class="text-left mb-3 mt-lg-0">{{ 'account.member.card.title' | translate }}</h5>
        <fgb-member-card showSeating="true"></fgb-member-card>
      </div>

      <div class="mt-5">
        <h5 class="text-left mb-3 mt-lg-0">{{ 'account.answer.title' | translate }}</h5>
        <fgbcl-edit-questions></fgbcl-edit-questions>
      </div>
    </div>
  </div>
</div>
