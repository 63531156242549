<div *ngIf="member$ | async as member">
  <form [formGroup]="detailsForm">
    <div class="">
      <label class="mb-0">{{ 'account.details.dob' | translate }}</label>
      <div
        [ngClass]="{ ' justify-content-between p-0 pl-3 h-34': isInEditMode }"
        class="d-flex align-items-center bg-muted py-2 pl-2 m-0 mt-1 rounded"
      >
        <span class="mr-3 material-icons" *ngIf="notInAnyEditMode"> date_range </span>
        <input
          type="text"
          class="edit-input p-0"
          formControlName="dateOfBirth"
          ngbDatepicker
          [minDate]="{ year: 1930, month: 1, day: 1 }"
          [maxDate]="{ year: maxYear, month: currentMonth, day: currentDate }"
          [startDate]="{ year: maxYear, month: currentMonth, day: currentDate }"
          #dobDatePicker="ngbDatepicker"
          (click)="dobDatePicker.toggle()"
        />
        <div class="input-group-append-calendar" *ngIf="isInEditMode">
          <button
            class="btn icon d-flex align-items-center justify-content-center bg-muted font-weight-bold py-0"
            type="button"
            (click)="dobDatePicker.toggle()"
            aria-label="Toggle date picker"
          >
            <span class="material-icons text-muted"> date_range </span>
          </button>
        </div>
      </div>
    </div>
    <div>
      <label class="mb-0 personal-info">{{ 'account.details.contact.no' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
        <span *ngIf="!isInEditMode" class="icon material-icons mr-3" aria-hidden="true">phone</span>
        <input type="number" class="w-100 edit-input p-0" formControlName="homeNumber" pattern="[0-9]*" inputmode="numeric" />
      </div>
    </div>
    <div *ngIf="notInAnyEditMode">
      <label id="email-label" class="mb-0 personal-info">{{ 'account.details.email' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons" aria-hidden="true">email</span>
        <span class="ml-3 w-75">
          <div>
            <span *ngIf="notInAnyEditMode">{{ member.EmailAddress1 }}</span>
            <input
              aria-labelledby="email-label"
              class="edit-input p-0"
              *ngIf="anyEditMode"
              type="text"
              formControlName="emailAddress"
            />
          </div>
        </span>
      </div>
    </div>

    <ng-container *ngIf="isLoading">
      <fgbcl-loading></fgbcl-loading>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <button
        type="button"
        class="edit-button font-weight-bold d-block mx-auto border-0 rounded text-white bg-primary"
        *ngIf="notInAnyEditMode"
        (click)="edit()"
      >
        Edit
      </button>
      <button
        #autoEditConfirm
        type="button"
        class="save-button font-weight-bold d-block mx-auto border-0 rounded text-white bg-success"
        *ngIf="autoEditMode"
        [ngClass]="disableIfFormIncomplete()"
        (click)="save()"
      >
        Confirm
      </button>
      <div class="d-flex justify-content-between">
        <button
          type="button"
          class="cancel-button font-weight-bold d-inline-block border-0 rounded text-white bg-danger"
          *ngIf="isInEditMode"
          (click)="stopEditing()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="save-button font-weight-bold d-inline-block border-0 rounded text-white bg-success"
          *ngIf="isInEditMode"
          [ngClass]="detailsForm.controls.dateOfBirth.value && detailsForm.controls.homeNumber.value ? '' : 'disabled'"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </ng-container>
  </form>
</div>
