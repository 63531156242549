import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberPurchaseItemDetailsComponent } from './components/member-purchase-item-details/member-purchase-item-details.component';
import { MemberPurchaseListComponent } from './components/member-purchase-list/member-purchase-list.component';
import { MemberPurchaseListItemComponent } from './components/member-purchase-list-item/member-purchase-list-item.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuctionBidListComponent } from './components/auction-bid-list/auction-bid-list.component';
import { AuctionBidListItemComponent } from './components/auction-bid-list-item/auction-bid-list-item.component';
import { AuctionBidDetailsComponent } from './components/auction-bid-details/auction-bid-details.component';
import { LayoutModule } from '../layouts/layout.module';
import { UserModule } from '../user/user.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LoyaltyModule } from '../loyalty/loyalty.module';
import { ShortLongDescriptionModule } from '@fgb/portal-component-library/src/lib/shared/short-long-description';
import { WalletRedeemListComponent } from './components/wallet-redeem-list/wallet-redeem-list.component';
import { WalletRedeemListItemComponent } from './components/wallet-redeem-list-item/wallet-redeem-list-item.component';
import { WalletRedeemedListComponent } from './components/wallet-redeemed-list/wallet-redeemed-list.component';
import { WalletRedeemedListItemComponent } from './components/wallet-redeemed-list-item/wallet-redeemed-list-item.component';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { LoyaltyIconModule } from '@fgb/portal-component-library/src/lib/shared/loyalty-icon';

@NgModule({
  declarations: [
    MemberPurchaseItemDetailsComponent,
    MemberPurchaseListComponent,
    MemberPurchaseListItemComponent,
    AuctionBidListComponent,
    AuctionBidListItemComponent,
    AuctionBidDetailsComponent,
    WalletRedeemListComponent,
    WalletRedeemListItemComponent,
    WalletRedeemedListComponent,
    WalletRedeemedListItemComponent,
  ],
  exports: [
    AuctionBidListComponent,
    AuctionBidListItemComponent,
    AuctionBidDetailsComponent,
    MemberPurchaseItemDetailsComponent,
    MemberPurchaseListComponent,
    MemberPurchaseListItemComponent,
    WalletRedeemListComponent,
    WalletRedeemListItemComponent,
    WalletRedeemedListComponent,
    WalletRedeemedListItemComponent,
  ],
  imports: [
    LayoutModule,
    UserModule,
    CommonModule,
    SharedModule,
    FormsModule,
    CarouselModule,
    QRCodeModule,
    RouterModule,
    NgbModule,
    LoyaltyModule,
    LoyaltyIconModule,
    ReactiveFormsModule,
    ErrorsModule,
    ShortLongDescriptionModule,
    InlineSVGModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
  ],
})
export class PurchaseModule {}
