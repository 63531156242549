<div class="banner d-lg-flex d-none">
  <h1>{{ 'lotto.page.title' | translate }}</h1>
</div>

<div class="container mixed-nav card-member d-block d-lg-none order-1 bg-white mb-3">
  <fgb-member-card showSeating="true"></fgb-member-card>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container mt-3">
  <fgbcl-lottos-list [isList]="true"></fgbcl-lottos-list>
</div>
