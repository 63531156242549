import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: 'ecash-success-page.component.html',
  styleUrls: ['ecash-success-page.component.scss'],
  selector: 'fgb-ecash-success',
})
export class EcashSuccessPageComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
