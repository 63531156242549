import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@fgb/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'fgb-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  errorCode$: Observable<string>;
  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService) {
    if (this.authService.hasCurrentRefreshToken()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.errorCode$ = this.route.queryParams.pipe(map((query) => query['errorCode']));
  }
}
