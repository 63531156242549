import { Component, Input, OnInit } from '@angular/core';
import {
  MixedLoyaltyItemType,
  MixedLoyaltyQuery,
  MixedLoyaltyWalletItem,
  ProcessProviderId,
  RewardsWalletService,
  RewardsWalletItem,
  RewardsWalletQuery,
  ScorecardService,
} from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss'],
})
export class WalletPageComponent implements OnInit {
  @Input() marketplaceFilterTypes: ProcessProviderId[] = [0, 1, 2, 3, 4, 5];
  @Input() itemTypes: MixedLoyaltyItemType[] = [MixedLoyaltyItemType.All];
  unclaimedWalletItems$: Observable<MixedLoyaltyWalletItem[]>;
  claimedWalletItems$: Observable<MixedLoyaltyWalletItem[]>;
  isList: boolean = true;
  walletItemsUnclaimed$: Observable<RewardsWalletItem[]>;

  constructor(
    private mixedLoyaltyQuery: MixedLoyaltyQuery,
    private scorecardService: ScorecardService,
    private rewardWalletItemsService: RewardsWalletService,
    private rewardWalletQuery: RewardsWalletQuery
  ) {}

  ngOnInit() {
    this.rewardWalletItemsService.fetchWalletItems().toPromise();
    this.walletItemsUnclaimed$ = this.rewardWalletQuery.selectRewardWalletItemsByStatus(false);
    /** select claimed marketplace and auction items */
    this.unclaimedWalletItems$ = this.mixedLoyaltyQuery.selectMixedLoyaltyWalletItems(
      false,
      this.marketplaceFilterTypes,
      this.itemTypes
    );
    /** select unclaimed marketplace and auction items */
    this.claimedWalletItems$ = this.mixedLoyaltyQuery.selectMixedLoyaltyWalletItems(
      true,
      this.marketplaceFilterTypes,
      this.itemTypes
    );
    this.scorecardService.fetchScorecards().toPromise();
  }
}
