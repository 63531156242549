<fgb-navigation-indicator></fgb-navigation-indicator>
<fgb-popup-notification></fgb-popup-notification>

<fgb-mobile-nav-menu *ifScreenSize="screenType.Mobile"></fgb-mobile-nav-menu>
<fgb-nav-bar *ifScreenSize="screenType.Desktop"></fgb-nav-bar>
<fgbcl-notification-toast></fgbcl-notification-toast>

<div id="main" role="main">
  <router-outlet></router-outlet>
</div>

<fgb-footer></fgb-footer>
