<div class="card redeem-offer-card border-0 rounded py-3 shadow mr-2 cursor-pointer">
  <div class="d-flex justify-content-center mb-2">
    <div class="back-button"></div>
    <img
      *ngIf="campaignEntitlement.image_URL"
      [ngClass]="{
        'image-opacity': campaignEntitlement.campaignRedemptions_Quantity == 0
      }"
      alt=""
      [src]="campaignEntitlement.image_URL"
      class="logo mb-3 rounded"
    />
    <img
      *ngIf="!campaignEntitlement.image_URL"
      [ngClass]="{
        'image-opacity': campaignEntitlement.campaignRedemptions_Quantity == 0
      }"
      alt=""
      src="assets/images/placeholders/campaign.jpg"
      class="logo mb-3 rounded"
    />
    <ng-container *ngIf="campaignEntitlement.campaignRedemptions_Quantity == 0">
      <span class="back-button icon material-icons text-grey text-right"> lock </span>
    </ng-container>
    <ng-container *ngIf="campaignEntitlement.campaignRedemptions_Quantity > 0">
      <span class="back-button icon material-icons text-success text-right font-weight-bold"> check_circle </span>
    </ng-container>
  </div>
  <div class="text-center font-size-12 font-weight-bold">{{ campaignEntitlement.CampaignName }}</div>

  <ng-container *ngIf="campaignEntitlement.Campaign_ExpiryDate">
    <div class="font-size-12 text-danger text-center pt-3">
      {{ 'offer.expiry' | translate }} {{ campaignEntitlement.Campaign_ExpiryDate | date : 'MMM d, y' }}
    </div>
  </ng-container>
</div>
