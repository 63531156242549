<div class="ecash-description border border-disabled bg-white rounded shadow py-3 pl-4 pr-1 px-lg-4 mb-4">
  <h5 class="text-primary text-black font-weight-bold text-capitalize font-size-16">
    {{ 'payment.description.title' | translate }}
  </h5>
  <hr class="vertical-line" />
  <div class="mt-3">
    <!--Col 1-->
    <div class="py-2">
      <span class="font-size-12 font-weight-bold"> {{ 'payment.description.step' | translate }} 1: </span>
      <span class="font-size-12">{{ 'payment.description.step.one' | translate }}</span>
    </div>
    <!--Col 2-->
    <div class="py-2">
      <span class="font-size-12 font-weight-bold"> {{ 'payment.description.step' | translate }} 2: </span>
      <span class="font-size-12">{{ 'payment.description.step.two' | translate }}</span>
    </div>
    <!--Col 3-->
    <div class="py-2">
      <span class="font-size-12 font-weight-bold"> {{ 'payment.description.step' | translate }} 3: </span>
      <span class="font-size-12">{{ 'payment.description.step.three' | translate }}</span>
    </div>
  </div>
</div>
