<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div
    class="member-card text-white bg-primary"
    [ngClass]="{
      'member-card-bg': (memberCard.ProductCode | productCode | translate) === 'Season Ticket Member',
      'non-member-card-bg': (memberCard.ProductCode | productCode | translate) !== 'Season Ticket Member'
    }"
  >
    <div class="d-flex flex-column justify-content-between h-100 position-relative">
      <!--Name and ID-->
      <div class="card-id w-100 text-center position-absolute">
        <p class="mb-0 card-name" *ngIf="memberDetails$ | async as memberDetails">
          {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
        </p>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
