<div class="container">
  <div class="voucher row border-0 shadow">
    <div class="voucher-title col-12 col-lg-7 border-0">
      <span class="voucher-title-text"> Voucher Code</span>
      <span class="voucher-title-description d-none d-lg-inline">{{'voucher.enter.code.here' | translate}}</span>
    </div>
    <div class="col-12 d-inline d-lg-none voucher-title-description">{{'voucher.enter.code.here'| translate}}</div>
    <div class="col-12 col-lg-5">
      <div class="voucher-box d-flex flex-column align-items-center">
        <div class="voucher-input">
          <input
            type="text"
            [(ngModel)]="voucherCode"
            placeholder="Insert Code Here"
            class="form-control border-0"
            (keyup.enter)="redeemVoucher()"
          />
          <button
            class="voucher-submit text-white border-0 icon material-icons"
            (click)="redeemVoucher()"
            [disabled]="disableButton"
          >
            navigate_next
          </button>
        </div>
        <div class="voucher-result-box">
          <fgbcl-errors></fgbcl-errors>
          <div class="font-size-12 redem-result" *ngIf="redemptionResult" [ngClass]="{ 'color-success': sucessfulRedemption }">
            {{ redemptionResult }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>