<ng-container *ngIf="!this.registrationStepService.isLoading">
  <div class="d-flex justify-content-between">
    <!-- Previous Button -->
    <button
      class="btn btn-danger font-weight-bold font-size-14 mb-0"
      (click)="goToPreviousStep()"
      [disabled]="isFirstTimeRegistration && !hasPreviousStep"
      [ngClass]="{ 'single-back-btn': hideNextBtn }"
    >
      <ng-container *ngIf="!backLabel; else backLabelInput">
        <ng-container *ngIf="hasPreviousStep; else noPreviousStep">Back</ng-container>
        <ng-template #noPreviousStep>Cancel</ng-template>
      </ng-container>
      <ng-template #backLabelInput>{{ backLabel }}</ng-template>
    </button>

    <!-- Next Button -->
    <button
      *ngIf="!hideNextBtn"
      class="btn btn-success font-weight-bold font-size-14 mb-0"
      (click)="nextButtonClicked.emit()"
      [disabled]="nextButtonDisabled"
    >
      <ng-container *ngIf="!nextLabel; else nextLabelInput"> Next </ng-container>
      <ng-template #nextLabelInput>{{ nextLabel }}</ng-template>
    </button>
  </div>
</ng-container>
