import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, SeatGeekAuthService } from '@fgb/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'fgb-seatgeek-callback',
  templateUrl: './seatgeek-callback.component.html',
  styleUrls: ['./seatgeek-callback.component.scss'],
})
export class SeatGeekCallbackComponent implements OnInit {
  cookieName: string = '_sa_sso_upid';
  constructor(
    private seatgeekAuthService: SeatGeekAuthService,
    private router: Router,
    private cookieService: CookieService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      // already logged in
      this.router.navigate(['/']);
    } else {
      // log through a code
      this.authenticateCode();
    }
  }

  /**
   * call to athenticate JWT
   * if failed response will return
   * user to login page.
   */
  authenticateCode() {
    let kccode = this.getProviderCodeToken('code');
    this.seatgeekAuthService
      .validateCode(kccode)
      .toPromise()
      .catch((err) => {
        if (err.error.Code) {
          this.router.navigate(['/login'], { queryParams: { errorCode: err.error.Code } });
        }
      });
  }

  /**
   * Get a query string parameter without case sensitivity.
   * @param parameter the parameter that you require the information for
   */
  getProviderCodeToken(parameter: string): string {
    const url = window.location.href;
    let paramValue;

    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(parameter);
    }

    if (paramValue) {
      return paramValue;
    }
    return '';
  }

  /**
   * Will check if domain cookie exists
   * and return this as a string.
   * @returns domain cookie from
   * sports alliance domain
   */
  getDomainCookie(): string {
    let domainCookie = '';
    domainCookie = this.cookieService.get(this.cookieName);

    return domainCookie;
  }
}
