<div
  ngbDropdown
  class="notification-button pt-lg-1"
  [class.open]="isOpen"
  (openChange)="toggleNotificationCenter($event)"
  #notificationDropdown="ngbDropdown"
>
  <button
    class="dropdown-toggle notification-btn btn px-lg-4"
    id="notificationDropdown"
    attr.aria-label="{{ 'nav.notification.screenreader' | translate }}"
    tabindex="-1"
    ngbDropdownToggle
  >
    <span class="icon notification-bell material-icons material-mobile-nav text-white" aria-hidden="true">notifications </span>
    <ng-container *ngIf="unseenNotificationCount$ | async">
      <span class="notification-badge">
        <div class="count-notification">{{ unseenNotificationCount$ | async }}</div>
      </span>
    </ng-container>
  </button>

  <div ngbDropdownMenu aria-labelledby="notificationDropdown" class="notification-dropdown shadow dropdown-menu border-0 p-0">
    <div class="notification-header d-flex pl-3">
      <div class="font-size-14 notification-subheading">
        {{ 'navbar.notifications.title' | translate : { fgbdefault: 'Notifications' } }}
      </div>
      <ng-container *ifScreenSize="screenSize.Mobile">
        <i class="material-icons close-btn text-grey" (click)="notificationDropdown.close()">cancel</i>
      </ng-container>
    </div>
    <fgbcl-notification-hub></fgbcl-notification-hub>
  </div>
  <div class="notification-mobile-backdrop"></div>
</div>
