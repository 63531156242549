<div class="banner d-none d-lg-flex" role="banner">
  <h1>{{ 'badges.page.title' | translate }}</h1>
</div>

<div class="container mixed-nav card-member d-block d-lg-none order-1 bg-white mb-3">
  <fgb-member-card showSeating="true"></fgb-member-card>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container mt-4">
  <fgbcl-badge-overview-list [designV2]="true" [phaseThree]="true"></fgbcl-badge-overview-list>
</div>
