<div role="contentinfo" class="footer text-white text-center">
  <div class="blue-line"></div>
  <div class="logos bg-primary row align-items-center justify-content-center m-0 py-4">
    <div class="col-12">
      <img src="assets/images/footer/CompassMinerals.png" width="60" height="50" />
      <img src="assets/images/footer/Children'sMercy.png" width="135" height="30" />
      <img src="assets/images/footer/UMB-Primary.png" width="90" height="30" />
      <img src="assets/images/footer/PriceChopperLogo.png" width="90" height="50" />
      <img src="assets/images/footer/BudLight-White.png" width="100" height="50" />
      <img src="assets/images/footer/BlueKCLinear.png" width="160" height="33" />
      <img src="assets/images/footer/MazumaCU.png" width="100" height="20" />
      <img src="assets/images/footer/CentralBank.png" width="160" height="30" />
    </div>
    <div class="col-12 mt-4">
      <img src="assets/images/footer/Adidas.png" height="55" />
    </div>
  </div>
  <div class="blue-line-2"></div>
  <div class="footer-text bg-primary d-flex align-items-center justify-content-center w-100">
    <div class="footer-content d-none d-lg-block">
      <img alt="" class="footer-logo" src="assets/images/MainLogo.svg" draggable="false" />
      <p class="text-white font-size-12 text-center mx-2">
        {{ 'nav.club.rights' | translate }}
      </p>
    </div>
    <div class="footer-content d-flex flex-column d-lg-none">
      <img alt="" class="footer-logo" src="assets/images/MainLogo.svg" draggable="false" />
      <span class="text-white font-size-12 pl-3 text-center">{{ 'nav.club.rights' | translate }}</span>
    </div>
  </div>
</div>
