import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'productCode' })
export class ProductCodePipe implements PipeTransform {
  seasonTicketMember: string = 'STM24';
  sportingBlueRewards: string = 'Single Game';
  transform(value: string) {
    switch (value) {
      case this.seasonTicketMember:
        return (value = 'account.member.card.season');
      case this.sportingBlueRewards:
        return (value = 'account.member.card.sporting.blue');
      default:
        return (value = 'account.member.card.member');
    }
  }
}
