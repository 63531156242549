import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Company, CompanyQuery, CompanyOfferLanguageModel, CompanyOfferModel, CampaignEntitlements } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-offers-list-item',
  templateUrl: './offers-list-item.component.html',
  styleUrls: ['./offers-list-item.component.scss'],
})
export class OffersListItemComponent implements OnInit {
  @Input() campaignEntitlement: CampaignEntitlements;
  @Input() campaignEntitlementEnable: boolean = false;
  @Input() offer: CompanyOfferModel;
  @Input() highlighted = false;
  @Output() navigateToOffer = new EventEmitter<number>();
  languageContent: CompanyOfferLanguageModel;
  currentCompany$: Observable<Company | undefined>;
  constructor(private companyQuery: CompanyQuery) {}

  navigatedToOffer() {
    this.navigateToOffer.emit(this.offer.OfferId);
  }

  ngOnInit() {
    let currentLcid = localStorage.getItem('lcid');

    if (!!currentLcid) {
      this.languageContent = !!this.offer.Content.filter((language) => language.LCID == parseInt(currentLcid as string))[0]
        ? this.offer.Content.filter((language) => language.LCID == parseInt(currentLcid as string))[0]
        : this.offer.Content[0];
    } else {
      this.languageContent = this.offer.Content[0];
    }

    this.currentCompany$ = this.companyQuery.selectEntity(this.offer.CompanyId);
  }
}
