<div class="banner" role="banner">
  <h1>{{ 'ecash.page.title' | translate }}</h1>
</div>

<div class="d-lg-none d-block container my-2">
  <div class="">
    <fgb-member-card showSeating="true"></fgb-member-card>
  </div>
</div>

<!-- Campaigns and Entitlements Offers -->
<fgb-campaigns-offers-list></fgb-campaigns-offers-list>
<!-- EO: Campaigns and Entitlements Offers -->

<div class="container mt-4">
  <div class="cards-container d-lg-none d-block">
    <fgbcl-card-token-list
      [regex]="regex"
      [provider]="provider"
      [redirectUrl]="'/cardtoken/result'"
      [failureUrl]="'/cardtoken/failure'"
    ></fgbcl-card-token-list>
  </div>

  <div class="d-lg-block d-none">
    <h5 class="text-primary text-capitalize text-center">{{ 'ecash.page.access.card' | translate }}</h5>
    <div class="text-center font-size-14">
      {{ 'ecash.page.qr.text' | translate }}
    </div>
  </div>
  <div class="ecash-info-box mt-lg-4 mt-3 mb-4 bg-white rounded shadow overflow-hidden text-black">
    <div class="border-bottom mx-3 py-2">
      <span class="heading-text text-primary">{{ 'sportingkccash.instructions.heading' | translate }}</span>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="pb-0 px-3 pt-3" [innerHTML]="'ecash.page.steps' | translate | markdownTranslate"></div>
      </div>
    </div>
  </div>

  <div class="cards-container d-lg-block d-none">
    <fgbcl-card-token-list
      [regex]="regex"
      [provider]="provider"
      [redirectUrl]="'/cardtoken/result'"
      [failureUrl]="'/cardtoken/failure'"
    ></fgbcl-card-token-list>
  </div>

  <div class="container mt-4">
    <fgb-transaction-history [transactionTypes]="[transactionType.ecash]" [ecashView]="true"></fgb-transaction-history>
  </div>
</div>
